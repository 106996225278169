<template>
  <div>
    <div class="container-buttons-report mb-1">
      <div class="align-content-end"></div>
      <div>
        <div class="noprint">
          <div class="d-flex ml-auto">
            <div
              class="d-flex"
              v-if="
                assessment_group_report && assessment_group_report.assessments
              "
            >
              <div class="ml-auto mr-0 d-flex">
                <strong
                  class="mr-2 align-content-center"
                  style="font-size: 10pt"
                >
                  FORMATIVAS
                </strong>
                <b-form-checkbox
                  class="align-content-center"
                  v-model="assessment_switches_type"
                  switch
                >
                  <strong style="font-size: 10pt">SUMATIVAS</strong>
                </b-form-checkbox>
              </div>
            </div>
            <GenericButtonDownload
              v-if="
                !is_loading &&
                assessment_group_report &&
                assessment_group_report.assessments
              "
              tooltip_text="Reporte XLSX"
              :click_button="DownloadExcel"
              :class="`ml-1`"
              :disabled_button="disabled_button"
              :icon_excel="true"
            ></GenericButtonDownload>
          </div>
        </div>
      </div>
    </div>

    <!-- b-table -->
    <b-skeleton-table
      v-if="is_loading"
      :rows="9"
      :columns="10"
      :table-props="{ bordered: true, striped: true }"
    ></b-skeleton-table>
    <div class="table-wrapper">
      <b-table-simple
        bordered
        no-border-collapse
        small
        responsive
        v-if="
          !is_loading &&
          assessment_group_report &&
          assessment_group_report.assessments &&
          assessment_group_report.achievement_ranges
        "
        class="table-assesment"
      >
        <b-thead>
          <b-tr>
            <b-th
              class="text-center align-middle primary-header"
              :colspan="
                5 + assessment_group_report.achievement_ranges.length * 2
              "
            >
              <div class="d-flex align-items-center justify-content-between">
                <span class="text-center mx-auto">
                  REPORTE DE ASSESSMENT POR
                  {{
                    $getVisibleNames(
                      "mesh.competence",
                      true,
                      "Competencias"
                    ).toUpperCase()
                  }}
                  DEL PERFIL DE EGRESO
                  <template v-if="selected_student_status != 2">
                    <InfoTooltip
                      :id="`info-student-status`"
                      class="mr-2"
                      tooltip_text=""
                    ></InfoTooltip>
                    <b-popover
                      :target="`info-student-status`"
                      triggers="hover"
                      placement="top"
                      boundary="viewport"
                    >
                      <template #title>
                        <div>
                          Este cuadro aplica solo para estudiantes
                          <strong>"En Régimen"</strong>
                        </div>
                      </template>
                      <strong>
                        <div>(✓) Cursada</div>
                        <div>(-) En Proceso</div>
                        <div>(x) Por Cursar</div>
                      </strong>
                    </b-popover>
                  </template>
                </span>
                <VisibleColumns
                  v-if="displayList.length > 0"
                  :id_button="`col-display-1-${main_assessments.join(',')}`"
                  :tooltip_text="''"
                >
                  <template #popover-title> Visualizar columnas </template>
                  <template #popover-content>
                    <template v-if="assessment_group_report != null">
                      <div class="visible_columns">
                        <template v-for="display in displayList">
                          <div
                            :key="'index' + display.index + 'id' + display.id"
                          >
                            <div class="noprint">
                              <b-form-checkbox
                                :id="`column-check-${display.index}`"
                                name="checkbox-1"
                                v-model="display.visible"
                                :disabled="
                                  displayList.filter(
                                    (a) => a.visible && a.id != display.id
                                  ).length < 1 && display.visible
                                "
                              >
                                {{ display.title }}
                                {{ display.order ? display.order : "" }}
                              </b-form-checkbox>
                            </div>
                          </div>
                        </template>
                      </div>
                    </template>
                  </template>
                </VisibleColumns>
              </div>
            </b-th>
          </b-tr>
          <b-tr>
            <b-th
              class="text-center align-middle secondary-header"
              v-if="
                displayList.some(
                  (x) =>
                    $equals(
                      x.title,
                      $getVisibleNames(
                        'mesh.competence',
                        true,
                        'Competencias'
                      ).toUpperCase()
                    ) && x.visible
                )
              "
              rowspan="2"
            >
              {{
                $getVisibleNames(
                  "mesh.competence",
                  true,
                  "Competencias"
                ).toUpperCase()
              }}
            </b-th>
            <b-th
              class="text-center align-middle secondary-header"
              rowspan="2"
              v-if="
                displayList.some(
                  (x) =>
                    $equals(
                      x.title,
                      $getVisibleNames(
                        'mesh.egressprofilematter',
                        true,
                        'Asignaturas'
                      ).toUpperCase()
                    ) && x.visible
                )
              "
            >
              {{
                $getVisibleNames(
                  "mesh.egressprofilematter",
                  true,
                  "Asignaturas"
                ).toUpperCase()
              }}
            </b-th>
            <template
              v-for="assessment_label in assessment_group_report.achievement_ranges"
            >
              <b-th
                v-if="
                  displayList.some(
                    (x) =>
                      $equals(
                        `${x.id} ${x.title}`,
                        `${
                          assessment_label.id
                        } ${assessment_label.name.toUpperCase()}`
                      ) && x.visible
                  )
                "
                colspan="2"
                v-b-tooltip.v-secondary.noninteractive="
                  `${assessment_label.start_achievement} ${
                    assessment_label.include_start_achievement ? '≤' : '<'
                  } X ${
                    assessment_label.include_finish_achievement ? '≤' : '<'
                  } ${assessment_label.finish_achievement}`
                "
                class="text-center align-middle secondary-header"
                :style="`background-color:${assessment_label.color} !important`"
                :key="`header-assessment-${assessment_label.id}`"
                :id="`header-assessment-top-${assessment_label.id}`"
              >
                {{ assessment_label.name.toUpperCase() }}
              </b-th>
            </template>
            <b-th
              class="text-center align-middle secondary-header"
              v-if="
                displayList.some(
                  (x) => $equals(x.title, 'SIN REGISTRO') && x.visible
                )
              "
              colspan="2"
            >
              SIN REGISTRO
            </b-th>
            <b-th
              class="text-center align-middle secondary-header"
              v-if="
                displayList.some(
                  (x) => $equals(x.title, 'TOTAL ESTUDIANTES') && x.visible
                )
              "
              rowspan="2"
            >
              TOTAL ESTUDIANTES
            </b-th>
          </b-tr>
          <b-tr>
            <template
              v-for="assessment in assessment_group_report.achievement_ranges"
            >
              <b-th
                v-if="
                  displayList.some(
                    (x) =>
                      $equals(
                        `${x.id} ${x.title}`,
                        `${assessment.id} ${assessment.name.toUpperCase()}`
                      ) && x.visible
                  )
                "
                :key="`primary-columns-${assessment.id}`"
                class="text-center align-middle secondary-header"
                >CANTIDAD</b-th
              >
              <b-th
                v-if="
                  displayList.some(
                    (x) =>
                      $equals(
                        `${x.id} ${x.title}`,
                        `${assessment.id} ${assessment.name.toUpperCase()}`
                      ) && x.visible
                  )
                "
                :key="`secondary-columns-${assessment.id}`"
                class="text-center align-middle secondary-header"
                >%</b-th
              >
            </template>
            <b-th
              v-if="
                displayList.some(
                  (x) => $equals(x.title, 'SIN REGISTRO') && x.visible
                )
              "
              class="text-center align-middle secondary-header"
              >CANTIDAD</b-th
            >
            <b-th
              v-if="
                displayList.some(
                  (x) => $equals(x.title, 'SIN REGISTRO') && x.visible
                )
              "
              class="text-center align-middle secondary-header"
              >%</b-th
            >
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="(assessment, index) in assessment_group_report.assessments"
            :key="`row-${assessment.id}-${index}`"
          >
            <!-- Competencias -->
            <b-td
              v-if="
                displayList.some(
                  (x) =>
                    $equals(
                      x.title,
                      $getVisibleNames(
                        'mesh.competence',
                        true,
                        'Competencias'
                      ).toUpperCase()
                    ) && x.visible
                )
              "
              class="text-left"
            >
              <span v-if="assessment.order">
                {{ assessment.order }}
              </span>
              {{ assessment.full_sentence }}
            </b-td>
            <!-- Asignaturas -->
            <b-td
              v-if="
                displayList.some(
                  (x) =>
                    $equals(
                      x.title,
                      $getVisibleNames(
                        'mesh.egressprofilematter',
                        true,
                        'Asignaturas'
                      ).toUpperCase()
                    ) && x.visible
                )
              "
              class="text-left"
            >
              <b-badge
                v-for="matter in assessment.matters_data"
                :key="`${assessment.id}-${matter.matter_id}`"
                style="font-size: 12px"
                class="m-0 mr-1"
              >
                <strong v-if="matter.state">{{ matter.state }}</strong>
                {{ matter.matter_name }}
              </b-badge>
            </b-td>
            <!-- assessment achievement_ranges -->
            <template
              v-for="achievement_range in assessment.assessment_achievement_ranges"
            >
              <template
                v-if="
                  displayList.some(
                    (x) =>
                      $equals(
                        `${x.id} ${x.title}`,
                        `${
                          achievement_range.achievement_range_id
                        } ${achievement_range.achievement_range_name.toUpperCase()}`
                      ) && x.visible
                  )
                "
              >
                <template v-if="assessment_switches_type">
                  <!-- CANTIDAD -->
                  <b-td
                    :key="`row-assessment-sumative-${achievement_range.achievement_range_id}-${assessment.id}-${index}`"
                    class="text-center"
                  >
                    <div>
                      {{ achievement_range.range_count_summative | Round }}
                    </div>
                  </b-td>
                  <!-- % -->
                  <b-td
                    :key="`row-assessment-sumative-%-${achievement_range.achievement_range_id}-${assessment.id}-${index}`"
                    class="text-center"
                  >
                    <div>
                      {{
                        achievement_range.range_percentage_summative | Round
                      }}%
                    </div>
                  </b-td>
                </template>
                <template v-else>
                  <b-td
                    :key="`row-assessment-formative-${achievement_range.achievement_range_id}-${assessment.id}-${index}`"
                    class="text-center"
                  >
                    <div>
                      {{ achievement_range.range_count_formative | Round }}
                    </div>
                  </b-td>
                  <b-td
                    :key="`row-assessment-formative-%-${achievement_range.achievement_range_id}-${assessment.id}-${index}`"
                    class="text-center"
                  >
                    <div>
                      {{
                        achievement_range.range_percentage_formative | Round
                      }}%
                    </div>
                  </b-td>
                </template>
              </template>
            </template>
            <!-- SIN REGISTRO -->
            <template v-if="assessment_switches_type">
              <b-td
                v-if="
                  displayList.some(
                    (x) => $equals(x.title, 'SIN REGISTRO') && x.visible
                  )
                "
                class="text-center"
              >
                <div>
                  {{ assessment.unregistered_count_summative }}
                </div>
              </b-td>
              <b-td
                v-if="
                  displayList.some(
                    (x) => $equals(x.title, 'SIN REGISTRO') && x.visible
                  )
                "
                class="text-center"
              >
                <div>
                  {{ assessment.unregistered_percentage_summative | Round }}%
                </div>
              </b-td>
            </template>
            <template v-else>
              <b-td
                v-if="
                  displayList.some(
                    (x) => $equals(x.title, 'SIN REGISTRO') && x.visible
                  )
                "
                class="text-center"
              >
                <div>
                  {{ assessment.unregistered_count_formative }}
                </div>
              </b-td>
              <b-td
                v-if="
                  displayList.some(
                    (x) => $equals(x.title, 'SIN REGISTRO') && x.visible
                  )
                "
                class="text-center"
              >
                <div>
                  {{ assessment.unregistered_percentage_formative | Round }}%
                </div>
              </b-td>
            </template>
            <!-- TOTAL ESTUDIANTES -->
            <b-td
              v-if="
                displayList.some(
                  (x) => $equals(x.title, 'TOTAL ESTUDIANTES') && x.visible
                )
              "
              class="text-center"
            >
              <div v-b-tooltip.v-secondary.noninteractive="`En Régimen`">
                R:{{ assessment.on_track_evaluatee_assessment_count }}
              </div>
              <div v-b-tooltip.v-secondary.noninteractive="`Total`">
                T:{{ countStudent(assessment) }}
              </div>
            </b-td>
          </b-tr>
          <b-tr v-if="assessment_group_report.assessments.length == 0">
            <b-td :colspan="displayList.length">
              No hay datos que mostrar
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <!-- Grafico -->
      <GenericGraphic
        v-if="assessmentOptions.length > 0"
        :labels="chartLabels"
        :datasets="chartDatasets"
        :legend_labels="legendLabels"
        :title_graphic="`REPORTE DE ASSESSMENT GRUPAL DE LOGROS DE ${$getVisibleNames(
          'mesh.competence',
          true,
          'Competencias'
        ).toUpperCase()} DEL ${$getVisibleNames(
          'mesh.egressprofile',
          false,
          'Perfil de Egreso'
        ).toUpperCase()}`"
        :total_students="totalStudents"
        :label_y_axis="`N° de Estudiantes`"
        :title_table_circular="`Assessment`"
      >
        <template #selector>
          <b-form-select
            id="assessmentSelector"
            v-model="selected_assessment"
            :options="assessmentOptions"
            @change="updateAssessment"
            class="select-graphic-type"
          ></b-form-select>
        </template>
      </GenericGraphic>
    </div>
  </div>
</template>

<script>
// import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "AssessmentRangeTable",
  components: {
    GenericButtonDownload: () =>
      import("@/components/reusable/Buttons/GenericButtonDownload"),
    VisibleColumns: () =>
      import("@/components/reusable/Buttons/VisibleColumns"),
    InfoTooltip: () => import("@/components/reusable/InfoTooltip"),
    GenericGraphic: () => import("@/components/reusable/GenericGraphic"),
  },
  props: {
    main_assessments: {
      type: Array,
      required: true,
    },
    achievement_range_group: {
      type: [Number, null],
      required: false,
      default: null,
    },
    selected_student_status: {
      type: Number,
      default: 1,
    },
    egress_profile: {
      type: Object,
    },
    campuses_: {
      type: Array,
    },
    cohorts_: {
      type: Array,
    },
    cycles_: {
      type: Array,
    },
  },
  data() {
    return {
      is_loading: false, // Variable para mostrar "skeletons"
      disabled_button: false,
      assessment_group_report: [],
      displayList: [],
      assessment_switches_type: true,
      selected_assessment: "compare_all", // Variable para guardar assessment del selector.
    };
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
    }),
    isOnTrack() {
      if (this.selected_student_status == 1) return true;
      else if (this.selected_student_status == 2) return false;
      return null;
    },
    assessmentOptions() {
      if (!this.assessment_group_report?.assessments) return [];

      const options = this.assessment_group_report.assessments.map((x) => ({
        value: x.id,
        text: `${x.assessment_type_short_name} ${x.order || ""}`.trim(),
      }));

      const firstAssessmentType =
        this.assessment_group_report.assessments[0]
          ?.assessment_type_short_name || "";

      // Añadir opción "Comparar todos"
      options.push({
        value: "compare_all",
        text: `Ver todos las ${firstAssessmentType}`,
      });

      return options;
    },
    totalStudents() {
      return this.assessment_group_report?.extra_data?.total_evaluatees || 0;
    },
    // Etiquetas del gráfico (eje X)
    chartLabels() {
      if (
        !this.assessment_group_report ||
        !this.assessment_group_report.assessments
      ) {
        return [];
      }

      if (this.selected_assessment == "compare_all") {
        return this.assessment_group_report.assessments.map(
          (assessment) =>
            `${assessment.assessment_type_short_name} ${assessment.order}`
        );
      }

      // Para la opción individual, mostrar el nombre del assessment y el order
      const selected = this.assessment_group_report.assessments.find(
        (x) => x.id == this.selected_assessment
      );
      return selected
        ? [`${selected.assessment_type_short_name} ${selected.order}`]
        : [];
    },

    // Datos del gráfico (eje Y)
    chartDatasets() {
      if (
        !this.assessment_group_report?.achievement_ranges ||
        !this.selected_assessment
      ) {
        return [];
      }

      const datasets = [];

      // Si se selecciona "Comparar todos"
      if (this.selected_assessment == "compare_all") {
        const assessmentsToCompare = this.assessment_group_report.assessments;

        // Crear un dataset por cada achievement_range
        this.assessment_group_report.achievement_ranges.forEach((range) => {
          const data = assessmentsToCompare.map((assessment) => {
            const matchingRange = assessment.assessment_achievement_ranges.find(
              (x) => x.achievement_range_id == range.id
            );
            return matchingRange
              ? this.assessment_switches_type
                ? matchingRange.range_count_summative
                : matchingRange.range_count_formative
              : 0;
          });

          datasets.push({
            label: range.name, // Nombre del rango
            data: data, // Datos para el gráfico
            backgroundColor: range.color, // Color del achievement_range
          });
        });

        // Agregar dataset para los sin Registro
        const unregisteredData = assessmentsToCompare.map((assessment) =>
          this.assessment_switches_type
            ? assessment.unregistered_count_summative
            : assessment.unregistered_count_formative
        );

        datasets.push({
          label: "Sin Registro", // Etiqueta para los sin Registro
          data: unregisteredData, // Datos de sin Registro
          backgroundColor: "#cccccc", // Color gris para diferenciar
        });
      } else {
        // Si se selecciona una opción individual
        const selected = this.assessment_group_report.assessments.find(
          (x) => x.id == this.selected_assessment
        );

        if (selected) {
          // Crear un dataset por cada achievement_range segun el assessment seleccionado
          this.assessment_group_report.achievement_ranges.forEach((range) => {
            const matchingRange = selected.assessment_achievement_ranges.find(
              (x) => x.achievement_range_id == range.id
            );

            datasets.push({
              label: range.name,
              data: [
                matchingRange
                  ? this.assessment_switches_type
                    ? matchingRange.range_count_summative
                    : matchingRange.range_count_formative
                  : 0,
              ],
              backgroundColor: range.color,
            });
          });

          // Agregar dataset para los sin Registro
          datasets.push({
            label: "Sin Registro",
            data: [
              this.assessment_switches_type
                ? selected.unregistered_count_summative
                : selected.unregistered_count_formative,
            ],
            backgroundColor: "#cccccc",
          });
        }
      }

      return datasets;
    },
    legendLabels() {
      if (
        this.assessment_group_report &&
        this.assessment_group_report.achievement_ranges
      ) {
        // Devuelve los nombres de los rangos
        const labels = this.assessment_group_report.achievement_ranges.map(
          (x) => ({
            label: x.name,
            color: x.color,
          })
        );

        // Añadir la etiqueta para los sin Registro
        labels.push({
          label: "Sin Registro", // Etiqueta para los sin Registro
          color: "#cccccc", // Color gris para los sin Registro
        });

        return labels;
      }
      return [];
    },
  },
  methods: {
    updateAssessment(selectedId) {
      this.selected_assessment = selectedId;
    },
    DownloadExcel() {
      this.disabled_button = true;
      this.$restful
        .Post(`assessment2/excel_report_group_competence_report/`, {
          egress_profile: this.egress_profile.id,
          cohort: this.cohorts_[0].cohort,
          campus_filters: this.campuses_
            ? this.campuses_.map((x) => x.id)
            : null,
          assessments: this.main_assessments,
          achievement_range_group: this.achievement_range_group,
          student_status: this.selected_student_status,
        })
        .then((response) => {
          if (response && response.file_url) {
            window.open(response.file_url, "_blank");
            this.disabled_button = false;
          }
        });
    },
    countStudent(assessment) {
      let count = 0;
      if (this.assessment_switches_type) {
        count += assessment.assessment_achievement_ranges.reduce((acc, obj) => {
          return parseFloat(acc) + parseFloat(obj.range_count_summative);
        }, 0);
        return count + assessment.unregistered_count_summative;
      } else {
        count += assessment.assessment_achievement_ranges.reduce((acc, obj) => {
          return parseFloat(acc) + parseFloat(obj.range_count_formative);
        }, 0);
        return count + assessment.unregistered_count_formative;
      }
    },
    getAssessmentRange() {
      if (this.main_assessments.length > 0) {
        this.is_loading = true;
        this.displayList = [];
        this.$restful
          .Post(`/assessment2/group_competence_report/`, {
            egress_profile: this.egress_profile.id,
            cohort: this.cohorts_[0].cohort,
            // cycle_filters: this.cycles_ ? this.cycles_.map((x) => x.id) : null,
            campus_filters: this.campuses_
              ? this.campuses_.map((x) => x.id)
              : null,
            assessments: this.main_assessments,
            achievement_range_group: this.achievement_range_group,
            is_on_track: this.isOnTrack,
          })
          .then((response) => {
            this.assessment_group_report = response;
            this.completeDisplayList(response);
            this.$swal.close();
            this.is_loading = false;
          })
          .catch(() => {
            this.is_loading = false;
            this.$swal.close();
            this.$swal.fire(
              "Error",
              "Hubo un problema al generar el reporte.",
              "error"
            );
          });
      }
    },
    completeDisplayList(response) {
      this.displayList = [
        {
          index: 0,
          id: this.$getVisibleNames(
            "mesh.competence",
            true,
            "Competencias"
          ).toUpperCase(),
          title: this.$getVisibleNames(
            "mesh.competence",
            true,
            "Competencias"
          ).toUpperCase(),
          visible: true,
        },
        {
          index: 1,
          id: this.$getVisibleNames(
            "mesh.egressprofilematter",
            true,
            "Asignaturas"
          ).toUpperCase(),
          title: this.$getVisibleNames(
            "mesh.egressprofilematter",
            true,
            "Asignaturas"
          ).toUpperCase(),
          visible: true,
        },
      ];
      let display_index = this.displayList.length;
      if (response.achievement_ranges && response.achievement_ranges.length > 0)
        response.achievement_ranges.forEach((element) => {
          this.displayList.push({
            index: display_index,
            id: element.id,
            title: `${element.name.toUpperCase()}`,
            order: "",
            visible: true,
          });
          display_index += 1;
        });
      this.displayList.push({
        index: display_index,
        id: "SIN REGISTRO",
        title: "SIN REGISTRO",
        visible: true,
      });
      display_index += 1;
      this.displayList.push({
        index: display_index,
        id: "TOTAL ESTUDIANTES",
        title: "TOTAL ESTUDIANTES",
        visible: true,
      });
    },
    countAchievementRange(achievement_range_id, achievement_range_list) {
      const achivement = achievement_range_list.find(
        (x) => x.achievement_range_id == achievement_range_id
      );
      if (achivement) {
        if (this.assessment_switches_type) return achivement.summative_count;
        else return achivement.formative_count;
      } else return "-";
    },
  },
  created() {
    this.getAssessmentRange();
  },
};
</script>

<style scoped>
.container-buttons-report {
  display: flex;
  justify-content: right;
}
.primary-header {
  background-color: var(--kl-menu-color) !important;
  color: white;
}
.secondary-header {
  background: #d0cece !important;
}
.col-display-1,
.col-display-2 {
  min-width: 200px;
  margin-left: 2em;
  background-color: var(--kl-primary-button-color) !important;
  color: #fff;
}
.col-display-1:hover,
.col-display-2:hover {
  background-color: var(--kl-primary-button-hover-color) !important;
  transition: all 0.3s;
}
.truncated-text {
  white-space: nowrap; /* Evita el salto de línea */
  overflow: hidden; /* Oculta el contenido que se desborda */
  text-overflow: ellipsis; /* Agrega "..." al final */
  width: 100%; /* Asegura que no sobrepase el contenedor */
}
.visible_columns {
  max-height: 300px;
  overflow: auto;
}

@media (max-width: 768px) {
  .table-wrapper {
    overflow-x: auto;
  }

  .table-assesment-range-table {
    width: 100%;
    min-width: 600px;
    border-collapse: collapse; /* Evita que las celdas se sobrepongan */
  }
  .table-assesment {
    width: 100%;
    min-width: 800px;
    border-collapse: collapse; /* Evita que las celdas se sobrepongan */
  }
}
/* .table-assesment-print-table {
  display: none;
} */

@media print {
  .table-assesment-print-table {
    display: block;
  }
  .badge {
    border: none;
  }
}
</style>

