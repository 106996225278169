var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container-buttons-report mb-1"},[_c('div',{staticClass:"align-content-end"}),_c('div',[_c('div',{staticClass:"noprint"},[_c('div',{staticClass:"d-flex ml-auto"},[(
              _vm.assessment_group_report && _vm.assessment_group_report.assessments
            )?_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"ml-auto mr-0 d-flex"},[_c('strong',{staticClass:"mr-2 align-content-center",staticStyle:{"font-size":"10pt"}},[_vm._v(" FORMATIVAS ")]),_c('b-form-checkbox',{staticClass:"align-content-center",attrs:{"switch":""},model:{value:(_vm.assessment_switches_type),callback:function ($$v) {_vm.assessment_switches_type=$$v},expression:"assessment_switches_type"}},[_c('strong',{staticStyle:{"font-size":"10pt"}},[_vm._v("SUMATIVAS")])])],1)]):_vm._e(),(
              !_vm.is_loading &&
              _vm.assessment_group_report &&
              _vm.assessment_group_report.assessments
            )?_c('GenericButtonDownload',{class:`ml-1`,attrs:{"tooltip_text":"Reporte XLSX","click_button":_vm.DownloadExcel,"disabled_button":_vm.disabled_button,"icon_excel":true}}):_vm._e()],1)])])]),(_vm.is_loading)?_c('b-skeleton-table',{attrs:{"rows":9,"columns":10,"table-props":{ bordered: true, striped: true }}}):_vm._e(),_c('div',{staticClass:"table-wrapper"},[(
        !_vm.is_loading &&
        _vm.assessment_group_report &&
        _vm.assessment_group_report.assessments &&
        _vm.assessment_group_report.achievement_ranges
      )?_c('b-table-simple',{staticClass:"table-assesment",attrs:{"bordered":"","no-border-collapse":"","small":"","responsive":""}},[_c('b-thead',[_c('b-tr',[_c('b-th',{staticClass:"text-center align-middle primary-header",attrs:{"colspan":5 + _vm.assessment_group_report.achievement_ranges.length * 2}},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('span',{staticClass:"text-center mx-auto"},[_vm._v(" REPORTE DE ASSESSMENT POR "+_vm._s(_vm.$getVisibleNames( "mesh.competence", true, "Competencias" ).toUpperCase())+" DEL PERFIL DE EGRESO "),(_vm.selected_student_status != 2)?[_c('InfoTooltip',{staticClass:"mr-2",attrs:{"id":`info-student-status`,"tooltip_text":""}}),_c('b-popover',{attrs:{"target":`info-student-status`,"triggers":"hover","placement":"top","boundary":"viewport"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',[_vm._v(" Este cuadro aplica solo para estudiantes "),_c('strong',[_vm._v("\"En Régimen\"")])])]},proxy:true}],null,false,2347339388)},[_c('strong',[_c('div',[_vm._v("(✓) Cursada")]),_c('div',[_vm._v("(-) En Proceso")]),_c('div',[_vm._v("(x) Por Cursar")])])])]:_vm._e()],2),(_vm.displayList.length > 0)?_c('VisibleColumns',{attrs:{"id_button":`col-display-1-${_vm.main_assessments.join(',')}`,"tooltip_text":''},scopedSlots:_vm._u([{key:"popover-title",fn:function(){return [_vm._v(" Visualizar columnas ")]},proxy:true},{key:"popover-content",fn:function(){return [(_vm.assessment_group_report != null)?[_c('div',{staticClass:"visible_columns"},[_vm._l((_vm.displayList),function(display){return [_c('div',{key:'index' + display.index + 'id' + display.id},[_c('div',{staticClass:"noprint"},[_c('b-form-checkbox',{attrs:{"id":`column-check-${display.index}`,"name":"checkbox-1","disabled":_vm.displayList.filter(
                                  (a) => a.visible && a.id != display.id
                                ).length < 1 && display.visible},model:{value:(display.visible),callback:function ($$v) {_vm.$set(display, "visible", $$v)},expression:"display.visible"}},[_vm._v(" "+_vm._s(display.title)+" "+_vm._s(display.order ? display.order : "")+" ")])],1)])]})],2)]:_vm._e()]},proxy:true}],null,false,2747130127)}):_vm._e()],1)])],1),_c('b-tr',[(
              _vm.displayList.some(
                (x) =>
                  _vm.$equals(
                    x.title,
                    _vm.$getVisibleNames(
                      'mesh.competence',
                      true,
                      'Competencias'
                    ).toUpperCase()
                  ) && x.visible
              )
            )?_c('b-th',{staticClass:"text-center align-middle secondary-header",attrs:{"rowspan":"2"}},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "mesh.competence", true, "Competencias" ).toUpperCase())+" ")]):_vm._e(),(
              _vm.displayList.some(
                (x) =>
                  _vm.$equals(
                    x.title,
                    _vm.$getVisibleNames(
                      'mesh.egressprofilematter',
                      true,
                      'Asignaturas'
                    ).toUpperCase()
                  ) && x.visible
              )
            )?_c('b-th',{staticClass:"text-center align-middle secondary-header",attrs:{"rowspan":"2"}},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "mesh.egressprofilematter", true, "Asignaturas" ).toUpperCase())+" ")]):_vm._e(),_vm._l((_vm.assessment_group_report.achievement_ranges),function(assessment_label){return [(
                _vm.displayList.some(
                  (x) =>
                    _vm.$equals(
                      `${x.id} ${x.title}`,
                      `${
                        assessment_label.id
                      } ${assessment_label.name.toUpperCase()}`
                    ) && x.visible
                )
              )?_c('b-th',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
                `${assessment_label.start_achievement} ${
                  assessment_label.include_start_achievement ? '≤' : '<'
                } X ${
                  assessment_label.include_finish_achievement ? '≤' : '<'
                } ${assessment_label.finish_achievement}`
              ),expression:"\n                `${assessment_label.start_achievement} ${\n                  assessment_label.include_start_achievement ? '≤' : '<'\n                } X ${\n                  assessment_label.include_finish_achievement ? '≤' : '<'\n                } ${assessment_label.finish_achievement}`\n              ",modifiers:{"v-secondary":true,"noninteractive":true}}],key:`header-assessment-${assessment_label.id}`,staticClass:"text-center align-middle secondary-header",style:(`background-color:${assessment_label.color} !important`),attrs:{"colspan":"2","id":`header-assessment-top-${assessment_label.id}`}},[_vm._v(" "+_vm._s(assessment_label.name.toUpperCase())+" ")]):_vm._e()]}),(
              _vm.displayList.some(
                (x) => _vm.$equals(x.title, 'SIN REGISTRO') && x.visible
              )
            )?_c('b-th',{staticClass:"text-center align-middle secondary-header",attrs:{"colspan":"2"}},[_vm._v(" SIN REGISTRO ")]):_vm._e(),(
              _vm.displayList.some(
                (x) => _vm.$equals(x.title, 'TOTAL ESTUDIANTES') && x.visible
              )
            )?_c('b-th',{staticClass:"text-center align-middle secondary-header",attrs:{"rowspan":"2"}},[_vm._v(" TOTAL ESTUDIANTES ")]):_vm._e()],2),_c('b-tr',[_vm._l((_vm.assessment_group_report.achievement_ranges),function(assessment){return [(
                _vm.displayList.some(
                  (x) =>
                    _vm.$equals(
                      `${x.id} ${x.title}`,
                      `${assessment.id} ${assessment.name.toUpperCase()}`
                    ) && x.visible
                )
              )?_c('b-th',{key:`primary-columns-${assessment.id}`,staticClass:"text-center align-middle secondary-header"},[_vm._v("CANTIDAD")]):_vm._e(),(
                _vm.displayList.some(
                  (x) =>
                    _vm.$equals(
                      `${x.id} ${x.title}`,
                      `${assessment.id} ${assessment.name.toUpperCase()}`
                    ) && x.visible
                )
              )?_c('b-th',{key:`secondary-columns-${assessment.id}`,staticClass:"text-center align-middle secondary-header"},[_vm._v("%")]):_vm._e()]}),(
              _vm.displayList.some(
                (x) => _vm.$equals(x.title, 'SIN REGISTRO') && x.visible
              )
            )?_c('b-th',{staticClass:"text-center align-middle secondary-header"},[_vm._v("CANTIDAD")]):_vm._e(),(
              _vm.displayList.some(
                (x) => _vm.$equals(x.title, 'SIN REGISTRO') && x.visible
              )
            )?_c('b-th',{staticClass:"text-center align-middle secondary-header"},[_vm._v("%")]):_vm._e()],2)],1),_c('b-tbody',[_vm._l((_vm.assessment_group_report.assessments),function(assessment,index){return _c('b-tr',{key:`row-${assessment.id}-${index}`},[(
              _vm.displayList.some(
                (x) =>
                  _vm.$equals(
                    x.title,
                    _vm.$getVisibleNames(
                      'mesh.competence',
                      true,
                      'Competencias'
                    ).toUpperCase()
                  ) && x.visible
              )
            )?_c('b-td',{staticClass:"text-left"},[(assessment.order)?_c('span',[_vm._v(" "+_vm._s(assessment.order)+" ")]):_vm._e(),_vm._v(" "+_vm._s(assessment.full_sentence)+" ")]):_vm._e(),(
              _vm.displayList.some(
                (x) =>
                  _vm.$equals(
                    x.title,
                    _vm.$getVisibleNames(
                      'mesh.egressprofilematter',
                      true,
                      'Asignaturas'
                    ).toUpperCase()
                  ) && x.visible
              )
            )?_c('b-td',{staticClass:"text-left"},_vm._l((assessment.matters_data),function(matter){return _c('b-badge',{key:`${assessment.id}-${matter.matter_id}`,staticClass:"m-0 mr-1",staticStyle:{"font-size":"12px"}},[(matter.state)?_c('strong',[_vm._v(_vm._s(matter.state))]):_vm._e(),_vm._v(" "+_vm._s(matter.matter_name)+" ")])}),1):_vm._e(),_vm._l((assessment.assessment_achievement_ranges),function(achievement_range){return [(
                _vm.displayList.some(
                  (x) =>
                    _vm.$equals(
                      `${x.id} ${x.title}`,
                      `${
                        achievement_range.achievement_range_id
                      } ${achievement_range.achievement_range_name.toUpperCase()}`
                    ) && x.visible
                )
              )?[(_vm.assessment_switches_type)?[_c('b-td',{key:`row-assessment-sumative-${achievement_range.achievement_range_id}-${assessment.id}-${index}`,staticClass:"text-center"},[_c('div',[_vm._v(" "+_vm._s(_vm._f("Round")(achievement_range.range_count_summative))+" ")])]),_c('b-td',{key:`row-assessment-sumative-%-${achievement_range.achievement_range_id}-${assessment.id}-${index}`,staticClass:"text-center"},[_c('div',[_vm._v(" "+_vm._s(_vm._f("Round")(achievement_range.range_percentage_summative))+"% ")])])]:[_c('b-td',{key:`row-assessment-formative-${achievement_range.achievement_range_id}-${assessment.id}-${index}`,staticClass:"text-center"},[_c('div',[_vm._v(" "+_vm._s(_vm._f("Round")(achievement_range.range_count_formative))+" ")])]),_c('b-td',{key:`row-assessment-formative-%-${achievement_range.achievement_range_id}-${assessment.id}-${index}`,staticClass:"text-center"},[_c('div',[_vm._v(" "+_vm._s(_vm._f("Round")(achievement_range.range_percentage_formative))+"% ")])])]]:_vm._e()]}),(_vm.assessment_switches_type)?[(
                _vm.displayList.some(
                  (x) => _vm.$equals(x.title, 'SIN REGISTRO') && x.visible
                )
              )?_c('b-td',{staticClass:"text-center"},[_c('div',[_vm._v(" "+_vm._s(assessment.unregistered_count_summative)+" ")])]):_vm._e(),(
                _vm.displayList.some(
                  (x) => _vm.$equals(x.title, 'SIN REGISTRO') && x.visible
                )
              )?_c('b-td',{staticClass:"text-center"},[_c('div',[_vm._v(" "+_vm._s(_vm._f("Round")(assessment.unregistered_percentage_summative))+"% ")])]):_vm._e()]:[(
                _vm.displayList.some(
                  (x) => _vm.$equals(x.title, 'SIN REGISTRO') && x.visible
                )
              )?_c('b-td',{staticClass:"text-center"},[_c('div',[_vm._v(" "+_vm._s(assessment.unregistered_count_formative)+" ")])]):_vm._e(),(
                _vm.displayList.some(
                  (x) => _vm.$equals(x.title, 'SIN REGISTRO') && x.visible
                )
              )?_c('b-td',{staticClass:"text-center"},[_c('div',[_vm._v(" "+_vm._s(_vm._f("Round")(assessment.unregistered_percentage_formative))+"% ")])]):_vm._e()],(
              _vm.displayList.some(
                (x) => _vm.$equals(x.title, 'TOTAL ESTUDIANTES') && x.visible
              )
            )?_c('b-td',{staticClass:"text-center"},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(`En Régimen`),expression:"`En Régimen`",modifiers:{"v-secondary":true,"noninteractive":true}}]},[_vm._v(" R:"+_vm._s(assessment.on_track_evaluatee_assessment_count)+" ")]),_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(`Total`),expression:"`Total`",modifiers:{"v-secondary":true,"noninteractive":true}}]},[_vm._v(" T:"+_vm._s(_vm.countStudent(assessment))+" ")])]):_vm._e()],2)}),(_vm.assessment_group_report.assessments.length == 0)?_c('b-tr',[_c('b-td',{attrs:{"colspan":_vm.displayList.length}},[_vm._v(" No hay datos que mostrar ")])],1):_vm._e()],2)],1):_vm._e(),(_vm.assessmentOptions.length > 0)?_c('GenericGraphic',{attrs:{"labels":_vm.chartLabels,"datasets":_vm.chartDatasets,"legend_labels":_vm.legendLabels,"title_graphic":`REPORTE DE ASSESSMENT GRUPAL DE LOGROS DE ${_vm.$getVisibleNames(
        'mesh.competence',
        true,
        'Competencias'
      ).toUpperCase()} DEL ${_vm.$getVisibleNames(
        'mesh.egressprofile',
        false,
        'Perfil de Egreso'
      ).toUpperCase()}`,"total_students":_vm.totalStudents,"label_y_axis":`N° de Estudiantes`,"title_table_circular":`Assessment`},scopedSlots:_vm._u([{key:"selector",fn:function(){return [_c('b-form-select',{staticClass:"select-graphic-type",attrs:{"id":"assessmentSelector","options":_vm.assessmentOptions},on:{"change":_vm.updateAssessment},model:{value:(_vm.selected_assessment),callback:function ($$v) {_vm.selected_assessment=$$v},expression:"selected_assessment"}})]},proxy:true}],null,false,1782632755)}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }